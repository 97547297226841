import { Action, combineReducers } from '@ngrx/store';
import { paddleFeatureKey as _paddleFeatureKey, PaddleState as _PaddleState, reducer as paddleReducer} from './paddle.reducer';

export const paddleFeatureKey = 'paddle';

export interface PaddleState {
  [ _paddleFeatureKey ]: _PaddleState;
}

export function reducers(state: PaddleState | undefined, action: Action) {
  return combineReducers({
    [_paddleFeatureKey]: paddleReducer,
  })(state, action);
}
